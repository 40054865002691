import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'; // For handling metadata and SEO
import {bannerStyles, bannerButtonStyles, bannerTitleStyles, bannerSubtitleStyles } from './theme';

// Hero Banner Component
const Banner = ({ title, subtitle, buttonText }) => {

    return (
        <>
            <Helmet>
                <title>{title} - Atomic System InfoTech Solutions</title>
                <meta name="description" content={subtitle} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={subtitle} />
                <meta property="og:type" content="website" />
            </Helmet>
            <Box sx={bannerStyles}>
                <Typography variant="h2" sx={bannerTitleStyles}>
                    {title}
                </Typography>

                <Typography variant="h5" sx={bannerSubtitleStyles}>
                    {subtitle}
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    sx={bannerButtonStyles}
                    component={Link}
                    to="/contact"
                >
                    {buttonText}
                    <ArrowDownwardIcon sx={{ ml: 1 }} />
                </Button>
            </Box>
        </>
    );
};

export default Banner;
