// Navbar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Helmet } from 'react-helmet';

const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const isMobile = useMediaQuery('(max-width: 768px)');

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const menuItems = [
        { label: 'Home', to: '/' },
        { label: 'About Us', to: '/about-us' },
        { label: 'Services', to: '/services' },
        { label: 'Products', to: '/products' },
        { label: 'Tech Stack', to: '/tech-stack' },
        { label: 'Our Process', to: '/process' },
        { label: 'Careers', to: '/careers' },
    ];

    return (
        <>
            <Helmet>
                <title>Atomic System InfoTech Solutions</title>
                <meta name="description" content="Leading IT solutions company offering web and mobile development, digital marketing, and more." />
                <link rel="canonical" href="/" />
            </Helmet>

            <AppBar position="fixed" sx={{ backgroundColor: '#030519', color: '#E6E6E6', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 2, md: 5 } }}>
                    <Typography variant="h6" sx={{ fontSize: { xs: '1.1rem', md: '1.4rem' }, fontWeight: 'bold' }}>
                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Atomic System InfoTech Solutions</Link>
                    </Typography>

                    {!isMobile ? (
                        <nav style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            {menuItems.map((item, index) => (
                                <Button key={index} color="inherit" component={Link} to={item.to} aria-label={item.label}>
                                    {item.label}
                                </Button>
                            ))}
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#075DD1', color: '#E6E6E6', fontWeight: '600', textTransform: 'none', '&:hover': { backgroundColor: '#182759' } }}
                                component={Link} to="/contact"
                                aria-label="Contact Us"
                            >
                                Contact Us
                            </Button>
                        </nav>
                    ) : (
                        <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                    )}

                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                        {menuItems.map((item, index) => (
                            <MenuItem
                                key={index}
                                onClick={handleMenuClose}
                                component={Link} to={item.to}
                                aria-label={item.label}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                        <MenuItem onClick={handleMenuClose}>
                            <Button variant="contained" sx={{ backgroundColor: '#075DD1', color: '#E6E6E6', fontWeight: '600', textTransform: 'none', width: '100%', '&:hover': { backgroundColor: '#182759' }}} component={Link} to="/contact" aria-label="Contact Us">
                                Contact Us
                            </Button>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Navbar;
