// ContactUs.js
import React from 'react';
import { Helmet } from 'react-helmet'; // For dynamic metadata
import Banner from '../components/Banner';
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export const ICON_HOVER_ANIMATION = {
    hover: { scale: 1.2, transition: { duration: 0.3 } },
};

export const PRIMARY_COLOR = '#030519';
export const socialLinks = [
    {
        icon: <FaFacebook />,
        label: 'Facebook',
        url: 'https://www.facebook.com/share/p/v3AcNtMYPN5311oo/',
        color: '#3b5998'
    },
    {
        icon: <FaLinkedin />,
        label: 'LinkedIn',
        url: 'https://www.linkedin.com/feed/update/urn:li:activity:7230772410185056257',
        color: '#0077b5'
    },
    {
        icon: <FaInstagram />,
        label: 'Instagram',
        url: 'https://www.instagram.com/p/C-y5-p5oBB1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
        color: '#E4405F'
    },
    {
        icon: <FaWhatsapp />,
        label: 'WhatsApp',
        url: 'https://wa.me/923071225906',
        color: '#25D366'
    },
    {
        icon: <MdEmail />,
        label: 'Email',
        url: 'mailto:atomicsysitsolutions@gmail.com',
        color: '#D44638'
    },
];

const Contact = () => {
    return (
        <>
            {/* Dynamic Metadata for SEO */}
            <Helmet>
                <title>Contact Us | Atomic System InfoTech Solutions</title>
                <meta
                    name="description"
                    content="Get in touch with Atomic System InfoTech Solutions for questions, support, or collaboration. Connect with us on social media or visit our office."
                />
                <meta name="keywords" content="Contact Atomic System, Social Media, Office Location, Collaboration, Support" />
                <link rel="canonical" href="https://atomicsysitsolutions.com/contact" />
            </Helmet>

            <Banner
                title="For More Details"
                subtitle="Get in touch! Whether you have questions, need support, or want to collaborate, reach out through any platform – or visit our office. We’re here to connect!"
                buttonText="Book Free 15 Minutes Consultation Meeting"
            />

            <Box
                sx={{
                    px: { xs: 4, md: 10 },
                    py: 6,
                    backgroundColor: '#E6E6E6',
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        mb: 4,
                        color: PRIMARY_COLOR,
                        fontWeight: 'bold',
                    }}
                >
                    Get in Touch
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        mb: 6,
                        color: '#4F4F4F',
                        maxWidth: 700,
                        mx: 'auto',
                    }}
                >
                    Connect with us on social media, drop us an email, or visit us at our office. We're here to help you.
                </Typography>

                {/* Social Icons with Accessibility Improvements */}
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    sx={{ mb: 8 }}
                >
                    {socialLinks.map((social, index) => (
                        <Grid item key={index}>
                            <IconButton
                                component="a"
                                href={social.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: social.color, fontSize: 40 }}
                                aria-label={`Visit our ${social.label}`}
                            >
                                <motion.div
                                    variants={ICON_HOVER_ANIMATION}
                                    whileHover="hover"
                                >
                                    {social.icon}
                                </motion.div>
                            </IconButton>
                            <Typography
                                variant="caption"
                                sx={{ display: 'block', color: '#4F4F4F' }}
                            >
                                {social.label}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>

                {/* Office Location */}
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h5"
                        sx={{
                            mb: 2,
                            fontWeight: 'bold',
                            color: PRIMARY_COLOR,
                        }}
                    >
                        Our Office Will be In
                    </Typography>
                    <Paper
                        sx={{
                            height: 500,
                            overflow: 'hidden',
                            boxShadow: 3,
                            borderRadius: 2,
                            '&:hover': {boxShadow: 6},
                        }}
                    >
                        <iframe
                            title="office-location"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d434587.2757682619!2d-74.30983049007158!3d40.69667075736717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e1!3m2!1sen!2s!4v1737173127576!5m2!1sen!2s"
                            width="100%"
                            height="100%"
                            style={{border: 0}}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </Paper>
                </Grid>
            </Box>
        </>
    );
};

export default Contact;
