// TechStack.js
import React, {useState} from 'react';
import Banner from '../components/Banner';
import {Box, Button, Grid, Link, Paper, Typography, useMediaQuery} from "@mui/material";
import Slider from "react-slick";
import { SiActix, SiAdobe, SiAndroid, SiAndroidstudio, SiAngular, SiApache, SiApachespark, SiAzuredevops, SiBitbucket,
         SiBlazor, SiCanva, SiCircleci, SiCodeigniter, SiCsharp, SiCypress, SiDart, SiDatadog, SiDbt, SiDigitalocean,
         SiDjango, SiDocker, SiElasticsearch, SiExpress, SiFastapi, SiFigma, SiFirebase, SiFlask, SiFlutter, SiGin,
         SiGithub, SiGithubactions, SiGitlab, SiGrafana, SiHeroku, SiHibernate, SiIntellijidea, SiInvision, SiJenkins,
         SiJest, SiJira, SiJupyter, SiKotlin, SiKtor, SiKubernetes, SiLaravel, SiMongodb, SiMysql, SiNestjs, SiNetlify,
         SiNewrelic, SiNextdotjs, SiPandas, SiPostgresql, SiPostman, SiPresto, SiPrisma, SiPrometheus, SiPycharm,
         SiPytest, SiPytorch, SiQuarkus, SiReact, SiRedis, SiRemix, SiReplit, SiRocket, SiSelenium, SiSentry, SiSketch,
         SiSlack, SiSplunk, SiSpringboot, SiSqlalchemy, SiSvelte, SiSwift, SiSymfony, SiTableau, SiTeamcity,
         SiTensorflow, SiTide, SiTravisci, SiTrello, SiTypescript, SiUnity, SiVapor, SiVercel, SiVisualstudiocode,
         SiVuedotjs, SiXamarin, SiXcode} from "react-icons/si";
import {FaAws, FaGithub, FaJava, FaJs, FaPhp, FaPython, FaRust, FaSwift} from "react-icons/fa";
import {FaGolang} from "react-icons/fa6";
import {Helmet} from "react-helmet";

export const languageFrameworks = [
    // Python
    {
        name: 'Python', icon: <FaPython />, description: 'Popular for web, data science, ML, and automating tasks in development.', link: 'https://www.geeksforgeeks.org/python-programming-language-tutorial/?ref=header_outind',
        frameworks: [
            { name: 'Django', icon: <SiDjango />, description: 'A high-level Python framework for fast, secure web app development.', link: 'https://www.geeksforgeeks.org/django-tutorial/?ref=header_outind' },
            { name: 'Flask', icon: <SiFlask />, description: 'A lightweight, flexible web framework in Python for rapid development.', link: 'https://www.geeksforgeeks.org/flask-tutorial/?ref=header_outind' },
            { name: 'FastAPI', icon: <SiFastapi />, description: 'A high-performance Python framework for modern, fast API development.', link: 'https://www.geeksforgeeks.org/fastapi-introduction/?ref=header_outind' },
            { name: 'TensorFlow', icon: <SiTensorflow />, description: 'An open-source ML and AI framework, widely used for deep learning.', link: 'https://www.geeksforgeeks.org/introduction-to-tensorflow/?ref=header_outind' },
            { name: 'PyTorch', icon: <SiPytorch />, description: 'A flexible, open-source library for machine learning and deep learning.', link: 'https://www.geeksforgeeks.org/getting-started-with-pytorch/?ref=header_outind' },
            { name: 'Pandas', icon: <SiPandas />, description: 'A Python library for data analysis with high-performance data structures.', link: 'https://www.geeksforgeeks.org/pandas-tutorial/?ref=header_outind' }
        ]
    },
    // JavaScript
    {
        name: 'JavaScript', icon: <FaJs />, description: 'Vital for web front-end (React) and back-end (Node.js), widely adopted in tech.', link: 'https://www.geeksforgeeks.org/javascript/?ref=header_outind',
        frameworks: [
            { name: 'React', icon: <SiReact />, description: 'A library for building UI components, mainly for single-page web apps.', link: 'https://www.geeksforgeeks.org/react/?ref=header_outind' },
            { name: 'Vue.js', icon: <SiVuedotjs />, description: 'A progressive framework for building UIs, known for simplicity.', link: 'https://www.geeksforgeeks.org/vue-js/?ref=header_outind' },
            { name: 'Angular', icon: <SiAngular />, description: 'A TypeScript-based framework for building dynamic single-page apps.', link: 'https://www.geeksforgeeks.org/introduction-to-angularjs/?ref=header_outind' },
            { name: 'Next.js', icon: <SiNextdotjs />, description: 'A React framework for building fast, optimized web applications.', link: 'https://www.geeksforgeeks.org/nextjs/?ref=header_outind' },
            { name: 'Express.js', icon: <SiExpress />, description: 'A web framework for Node.js, widely used to build APIs and apps.', link: 'https://www.geeksforgeeks.org/express-js/?ref=header_outind' },
            { name: 'Svelte', icon: <SiSvelte />, description: 'A UI framework that compiles components to highly efficient JavaScript.', link: 'https://www.geeksforgeeks.org/svelte-introduction-and-installation/?ref=header_outind' }
        ]
    },
    // TypeScript
    {
        name: 'TypeScript', icon: <SiTypescript />, description: 'JavaScript superset with static typing, favored in large-scale application dev.', link: 'https://www.geeksforgeeks.org/typescript/?ref=header_outind',
        frameworks: [
            { name: 'NestJS', icon: <SiNestjs />, description: 'A progressive framework for scalable and efficient server-side apps.', link: 'https://www.geeksforgeeks.org/nestjs/?ref=header_outind' },
            { name: 'Next.js (TypeScript)', icon: <SiNextdotjs />, description: 'A React framework with TypeScript support for quick, smooth apps.', link: 'https://www.geeksforgeeks.org/nextjs/?ref=header_outind' },
            { name: 'Angular (TypeScript)', icon: <SiAngular />, description: 'A TypeScript-based framework for scalable, maintainable web apps.', link: 'https://www.geeksforgeeks.org/introduction-to-angularjs/?ref=header_outind' },
            { name: 'Remix', icon: <SiRemix />, description: 'A fast framework for creating modern web applications with React.', link: 'https://www.geeksforgeeks.org/remix-introduction-installation/?ref=header_outind' }
        ]
    },
    // Java
    {
        name: 'Java', icon: <FaJava />, description: 'Dominates enterprise apps, backend, and Android; stable and widely implemented.', link: 'https://www.geeksforgeeks.org/java/?ref=header_outind',
        frameworks: [
            { name: 'Spring Boot', icon: <SiSpringboot />, description: 'A framework for building Java apps with embedded servers, no XML.', link: 'https://www.geeksforgeeks.org/spring-boot/?ref=header_outind' },
            { name: 'Hibernate', icon: <SiHibernate />, description: 'A tool for mapping Java objects to relational databases with ease.', link: 'https://www.geeksforgeeks.org/introduction-to-hibernate-framework/?ref=header_outind' },
            { name: 'Apache Struts', icon: <SiApache />, description: 'A Java framework for building web apps using the MVC architecture.', link: 'https://www.geeksforgeeks.org/introduction-and-working-of-struts-web-framework/?ref=header_outind' },
            { name: 'Quarkus', icon: <SiQuarkus />, description: 'A Java framework optimized for cloud-native apps, using GraalVM.', link: 'https://www.geeksforgeeks.org/difference-between-spring-boot-and-quarkus/?ref=header_outind' }
        ]
    },
    // Kotlin
    {
        name: 'Kotlin', icon: <SiKotlin />, description: 'Android’s modern choice over Java; concise, safer, and easy-to-read language.', link: 'https://www.geeksforgeeks.org/kotlin-programming-language/?ref=header_outind',
        frameworks: [
            { name: 'Ktor', icon: <SiKtor />, description: 'A flexible, asynchronous Kotlin framework for building web apps.', link: 'https://www.geeksforgeeks.org/kotlin-libraries/?ref=header_outind' },
            { name: 'Spring Boot (Kotlin)', icon: <SiSpringboot />, description: 'A Kotlin-friendly Spring Boot for easy backend app development.', link: 'https://www.geeksforgeeks.org/kotlin-libraries/?ref=header_outind' },
            { name: 'Android Architecture Components', icon: <SiAndroid />, description: 'Libraries to help design maintainable, robust Android apps.', link: 'https://www.geeksforgeeks.org/kotlin-libraries/?ref=header_outind' }
        ]
    },
    // Swift
    {
        name: 'Swift', icon: <FaSwift />, description: 'Built for iOS/macOS development, Swift powers Apple apps with high performance.', link: 'https://www.geeksforgeeks.org/swift-tutorial/?ref=header_outind',
        frameworks: [
            { name: 'SwiftUI', icon: <SiSwift />, description: 'A declarative framework for building native UIs across Apple devices.', link: 'https://www.geeksforgeeks.org/swift-tutorial/?ref=header_outind' },
            { name: 'Vapor', icon: <SiVapor />, description: 'A server-side Swift framework for building fast, secure web apps.', link: 'https://www.geeksforgeeks.org/swift-tutorial/?ref=header_outind' }
        ]
    },
    // Rust
    {
        name: 'Rust', icon: <FaRust />, description: 'Performance-focused with memory safety, used in system programming and beyond.', link: 'https://www.geeksforgeeks.org/introduction-to-rust-programming-language/?ref=header_outind',
        frameworks: [
            { name: 'Rocket', icon: <SiRocket />, description: 'A Rust web framework focused on safety, speed, and simplicity.', link: 'https://www.geeksforgeeks.org/top-rust-libraries/?ref=header_outind' },
            { name: 'Actix', icon: <SiActix />, description: 'A Rust framework for fast, scalable, actor-based web apps.', link: 'https://www.geeksforgeeks.org/top-rust-libraries/?ref=header_outind' },
            { name: 'Tide', icon: <SiTide />, description: 'A minimal Rust framework designed for async, rapid web development.', link: 'https://www.geeksforgeeks.org/top-rust-libraries/?ref=header_outind' }
        ]
    },
    // PHP
    {
        name: 'PHP', icon: <FaPhp />, description: 'Important for CMSs like WordPress, PHP remains a web development staple.', link: 'https://www.geeksforgeeks.org/php-tutorial/?ref=header_outind',
        frameworks: [
            { name: 'Laravel', icon: <SiLaravel />, description: 'A PHP framework with elegant syntax for modern, scalable web apps.', link: 'https://www.geeksforgeeks.org/introduction-to-laravel-and-mvc-framework/?ref=header_outind' },
            { name: 'Symfony', icon: <SiSymfony />, description: 'A flexible PHP framework for building robust, large web applications.', link: 'https://www.geeksforgeeks.org/installation-and-configuration-symfony-framework/?ref=header_outind' },
            { name: 'CodeIgniter', icon: <SiCodeigniter />, description: 'A PHP framework for lightweight, rapid application development.', link: 'https://www.geeksforgeeks.org/introduction-to-codeignitor-php/?ref=header_outind' }
        ]
    },
    // SQL
    {
        name: 'SQL', icon: <SiMysql />, description: 'Database language key for data management and analytics, not general-purpose.', link: 'https://www.geeksforgeeks.org/sql-tutorial/?ref=header_outind',
        frameworks: [
            { name: 'Apache Spark SQL', icon: <SiApache />, description: 'A framework for distributed data processing, supporting SQL-based analysis.', link: 'https://www.geeksforgeeks.org/components-of-apache-spark/?ref=header_outind' },
            { name: 'Presto', icon: <SiPresto />, description: 'A distributed SQL engine for high-speed queries on large datasets.', link: 'https://www.geeksforgeeks.org/overview-of-apache-presto/?ref=header_outind' },
            { name: 'DBT', icon: <SiDbt />, description: 'A transformation framework for analysts, turning raw data into insights.', link: 'https://www.geeksforgeeks.org/what-is-dbt/?ref=header_outind' }
        ]
    },
    // C#
    {
        name: 'C#', icon: <SiCsharp />, description: 'Popular in enterprise, Windows, and game dev with Unity, a versatile language.', link: 'https://www.geeksforgeeks.org/csharp-programming-language/?ref=header_outind',
        frameworks: [
            { name: 'Unity', icon: <SiUnity />, description: 'A cross-platform engine for building 2D/3D games and simulations.', link: 'https://www.geeksforgeeks.org/unity-introduction-to-interface/?ref=header_outind' },
            { name: 'Blazor', icon: <SiBlazor />, description: 'A C# framework for building web applications without JavaScript.', link: 'https://www.geeksforgeeks.org/microsoft-azure-blazor-and-azure-functions-for-serverless-websites/?ref=header_outind' },
            { name: 'Xamarin', icon: <SiXamarin />, description: 'A framework for building cross-platform mobile apps using C#.', link: 'https://www.geeksforgeeks.org/introduction-to-xamarin-a-software-for-mobile-app-development-and-app-creation/?ref=header_outind' }
        ]
    },
    // Go
    {
        name: 'Go', icon: <FaGolang />, description: 'Google’s language for backend, microservices, and cloud with a simple syntax.', link: 'https://www.geeksforgeeks.org/golang/?ref=header_outind',
        frameworks: [
            { name: 'Gin', icon: <SiGin />, description: 'A minimal, efficient Go framework for fast, scalable web applications.', link: 'https://www.geeksforgeeks.org/top-golang-frameworks/?ref=header_outind' },
        ]
    },
    // Dart
    {
        name: 'Dart', icon: <SiDart />, description: 'Used with Flutter, Google’s framework for cross-platform mobile applications.', link: 'https://www.geeksforgeeks.org/dart-tutorial/?ref=header_outind',
        frameworks: [
            { name: 'Flutter', icon: <SiFlutter />, description: 'A toolkit for building native apps across mobile, web, and desktop.', link: 'https://www.geeksforgeeks.org/flutter-tutorial/?ref=header_outind' },
        ]
    },
];

const toolsDomain = [
    {
        name: 'Designing',
        icon: <SiFigma />,
        description: 'Tools for designing and prototyping user interfaces and user experiences.',
        tools: [
            { name: "Figma", icons: <SiFigma />, description: "Design and prototype tool for collaborative UI/UX workflows in cloud.", link: 'https://www.figma.com/' },
            { name: "Adobe XD", icons: <SiAdobe />, description: "A design tool for creating web and mobile prototypes with Adobe integration.", link: 'https://www.adobe.com/'  },
            { name: "Sketch", icons: <SiSketch />, description: "Digital design software for web and mobile interfaces with plugins and ease.", link: 'https://www.sketchup.com/en'  },
            { name: "InVision", icons: <SiInvision />, description: "Product design platform enabling collaborative workflows for UI/UX teams.", link: 'https://appsource.microsoft.com/en-us/product/office/WA200003579'  },
            { name: "Canva", icons: <SiCanva />, description: "Web-based tool with templates for creating social media and graphics easily.", link: 'https://www.canva.com/'  }
        ]
    },
    {
        name: 'Development',
        icon: <SiVisualstudiocode />,
        description: 'Tools for writing, testing, and debugging code in various programming languages.',
        tools: [
            { name: "Visual Studio Code", icons: <SiVisualstudiocode />, description: "Powerful editor with debugging, Git, and multiple language support extensions.", link: 'https://code.visualstudio.com/' },
            { name: "IntelliJ IDEA", icons: <SiIntellijidea />, description: "Java IDE for JVM languages with robust debugging and code completion.", link: 'https://www.jetbrains.com/idea/'  },
            { name: "PyCharm", icons: <SiPycharm />, description: "IDE for Python with code assistance, debugging, and Django support.", link: 'https://www.jetbrains.com/pycharm/'  },
            { name: "Android Studio", icons: <SiAndroidstudio />, description: "IDE for Android app development with tools for coding, testing, and debugging.", link: 'https://developer.android.com/studio'  },
            { name: "Xcode", icons: <SiXcode />, description: "IDE for macOS/iOS with powerful debugging and testing for app development.", link: 'https://developer.apple.com/xcode/'  },
            { name: "Repl.it", icons: <SiReplit />, description: "Online platform to write, run, and share code in various languages in-browser.", link: 'https://replit.com/'  },
            { name: "GitHub Codespaces", icons: <SiGithub />, description: "Browser-based dev environment in GitHub for writing, testing, and debugging.", link: 'https://github.com/features/codespaces'  }
        ]
    },
    {
        name: 'Version Control',
        icon: <FaGithub />,
        description: 'Tools for version control, code management, and team collaboration.',
        tools: [
            { name: "GitHub", icons: <FaGithub />, description: "Platform for Git version control, code management, and team collaboration.", link: 'https://github.com/'  },
            { name: "GitLab", icons: <SiGitlab />, description: "Git repository and DevOps platform with CI/CD and project tracking tools.", link: 'https://gitlab.com/gitlab-org/gitlab'  },
            { name: "Bitbucket", icons: <SiBitbucket />, description: "Git version control platform that integrates with Jira for project workflows.", link: 'https://bitbucket.org/'  },
            { name: "JIRA", icons: <SiJira />, description: "Agile project management tool with issue tracking, sprints, and workflow.", link: 'https://www.atlassian.com/software/jira'  },
            { name: "Trello", icons: <SiTrello />, description: "Visual project management tool with boards, lists, and cards for tasks.", link: 'https://trello.com/'  },
            { name: "Slack", icons: <SiSlack />, description: "Team messaging platform with channels, DMs, and tool integrations.", link: 'https://slack.com/'  }
        ]
    },
    {
        name: 'Deployment',
        icon: <SiDocker />,
        description: 'Tools for deploying, managing, and scaling applications using containers.',
        tools: [
            { name: "Docker", icons: <SiDocker />, description: "Tool for building, deploying, and managing applications in containers.", link: 'https://www.docker.com/'  },
            { name: "Kubernetes", icons: <SiKubernetes />, description: "Platform for automated deployment, scaling, and management of containers.", link: 'https://kubernetes.io/'  },
            { name: "Heroku", icons: <SiHeroku />, description: "PaaS for building, running, and scaling apps with managed runtime services.", link: 'https://www.heroku.com/'  },
            { name: "AWS Elastic Beanstalk", icons: <FaAws />, description: "Managed service for deploying, running, and scaling applications on AWS.", link: 'https://aws.amazon.com/elasticbeanstalk/?trk=8e98a4bf-1153-4bca-8e0a-d26826bc487e&sc_channel=ps&s_kwcid=AL!4422!10!71674650271948!71675176766112&ef_id=353aa5d459ac1399a8f1c258271f6a07:G:s&msclkid=353aa5d459ac1399a8f1c258271f6a07'  },
            { name: "Netlify", icons: <SiNetlify />, description: "Platform for deploying static sites and serverless apps with Git integration.", link: 'https://www.netlify.com/'  },
            { name: "Vercel", icons: <SiVercel />, description: "Platform for frontend developers to build and deploy sites and apps fast.", link: 'https://vercel.com/'  },
            { name: "Firebase", icons: <SiFirebase />, description: "Platform offering database, hosting, authentication, and cloud functions.", link: 'https://firebase.google.com/'  },
            { name: "DigitalOcean App Platform", icons: <SiDigitalocean />, description: "PaaS to build, deploy, and scale apps in multiple programming languages.", link: 'https://www.digitalocean.com/products/app-platform'  }
        ]
    },
    {
        name: 'CI/CD',
        icon: <SiJenkins />,
        description: 'Continuous integration and continuous delivery tools for automating workflows.',
        tools: [
            { name: "GitHub Actions", icons: <SiGithubactions />, description: "CI/CD platform to automate workflows like tests and deployments on GitHub.", link: 'https://github.com/features/actions'  },
            { name: "Jenkins", icons: <SiJenkins />, description: "Open-source automation server for CI/CD with extensive plugin support.", link: 'https://www.jenkins.io/'  },
            { name: "CircleCI", icons: <SiCircleci />, description: "CI/CD platform to automate code builds, tests, and deployment processes.", link: 'https://circleci.com/docs/about-circleci/'  },
            { name: "Travis CI", icons: <SiTravisci />, description: "Cloud CI/CD tool for automating builds and tests for GitHub repositories.", link: 'https://www.travis-ci.com/'  },
            { name: "GitLab CI/CD", icons: <SiGitlab />, description: "CI/CD service in GitLab for automating tests, workflows, and deployments.", link: 'https://docs.gitlab.com/ee/ci/'  },
            { name: "TeamCity", icons: <SiTeamcity />, description: "CI/CD server by JetBrains with support for various languages and projects.", link: 'https://www.jetbrains.com/teamcity/'  },
            { name: "Azure DevOps", icons: <SiAzuredevops />, description: "Microsoft DevOps suite for CI/CD, version control, and project tracking.", link: 'https://azure.microsoft.com/en-us/products/devops/?msockid=38f76f6bb6a461c706a07b16b7ad607b'  }
        ]
    },
    {
        name: 'Testing & Debugging',
        icon: <SiSelenium />,
        description: 'Testing frameworks for ensuring application quality and performance.',
        tools: [
            { name: "Selenium", icons: <SiSelenium />, description: "Framework for web automation, enabling user simulations for app testing.", link: 'https://www.selenium.dev/'  },
            { name: "Jest", icons: <SiJest />, description: "JS testing framework for unit and integration tests with mocking support.", link: 'https://jestjs.io/'  },
            { name: "Cypress", icons: <SiCypress />, description: "JavaScript end-to-end testing framework with live reloading and debug.", link: 'https://www.cypress.io/'  },
            { name: "PyTest", icons: <SiPytest />, description: "Python framework for scalable unit and integration testing automation.", link: 'https://pypi.org/project/pytest/'  },
            { name: "Postman", icons: <SiPostman />, description: "API development tool for sending requests and inspecting HTTP responses.", link: 'https://www.postman.com/'  }
        ]
    },
    {
        name: 'Databases',
        icon: <SiPostgresql />,
        description: 'Relational and NoSQL databases for managing application data.',
        tools: [
            { name: "PostgreSQL", icons: <SiPostgresql />, description: "Reliable open-source SQL database for complex applications and extensibility.", link: 'https://www.postgresql.org/'  },
            { name: "MySQL", icons: <SiMysql />, description: "Open-source relational database for web apps with speed and flexibility.", link: 'https://www.mysql.com/'  },
            { name: "MongoDB", icons: <SiMongodb />, description: "NoSQL database for scalable, JSON-like data storage in web applications.", link: 'https://www.mongodb.com/?msockid=38f76f6bb6a461c706a07b16b7ad607b'  },
            { name: "Firebase Realtime Database", icons: <SiFirebase />, description: "Cloud-hosted NoSQL database syncing data in real-time for mobile/web.", link: 'https://firebase.google.com/docs/database/'  },
            { name: "Redis", icons: <SiRedis />, description: "In-memory key-value store used for caching, messaging, and real-time apps.", link: 'https://redis.io/'  },
            { name: "Prisma", icons: <SiPrisma />, description: "Toolkit for Node.js/TypeScript for easy database access and schema management.", link: 'https://www.prisma.io/'  },
            { name: "SQLAlchemy", icons: <SiSqlalchemy />, description: "Python SQL toolkit and ORM for database interaction and complex queries.", link: 'https://www.sqlalchemy.org/'  }
        ]
    },
    {
        name: 'Data Science & AI',
        icon: <SiTensorflow />,
        description: 'Tools for machine learning, data analysis, and AI model deployment.',
        tools: [
            { name: "Jupyter Notebook", icons: <SiJupyter />, description: "Interactive coding, visualizations, and narrative environment for data.", link: 'https://jupyter.org/'  },
            { name: "TensorFlow", icons: <SiTensorflow />, description: "Machine learning library for creating and deploying scalable ML models.", link: 'https://www.tensorflow.org/'  },
            { name: "PyTorch", icons: <SiPytorch />, description: "Python ML framework for rapid prototyping and deploying AI models.", link: 'https://pytorch.org/'  },
            { name: "Apache Spark", icons: <SiApachespark />, description: "Unified analytics engine for big data processing, ML, and stream tasks.", link: 'https://spark.apache.org/'  },
            { name: "Pandas", icons: <SiPandas />, description: "Data manipulation library for Python with DataFrames and analysis tools.", link: 'https://pandas.pydata.org/'  },
            { name: "Tableau", icons: <SiTableau />, description: "Visualization tool for interactive dashboards and business intelligence.", link: 'https://www.tableau.com/'  }
        ]
    },
    {
        name: 'Monitoring',
        icon: <SiPrometheus />,
        description: 'Monitoring tools for tracking performance and real-time system health.',
        tools: [
            { name: "Prometheus", icons: <SiPrometheus />, description: "Monitoring and time-series database system with powerful querying.", link: 'https://prometheus.io/docs/introduction/overview/'  },
            { name: "Grafana", icons: <SiGrafana />, description: "Analytics platform for visualizing time-series data with interactive dashboards.", link: 'https://grafana.com/'  },
            { name: "ELK Stack", icons: <SiElasticsearch />, description: "Centralized logging platform with Elasticsearch, Logstash, and Kibana.", link: 'https://www.elastic.co/elastic-stack/'  },
            { name: "New Relic", icons: <SiNewrelic />, description: "Platform for monitoring app performance and system metrics in real-time.", link: 'https://newrelic.com/'  },
            { name: "Datadog", icons: <SiDatadog />, description: "Monitoring and analytics platform for infrastructure and application logs.", link: 'https://www.datadoghq.com/'  },
            { name: "Sentry", icons: <SiSentry />, description: "Real-time error tracking tool to monitor and fix app crashes.", link: 'https://sentry.io/welcome/'  },
            { name: "Splunk", icons: <SiSplunk />, description: "Data platform for monitoring, analyzing, and visualizing machine data.", link: 'https://www.splunk.com/'  }
        ]
    }
];

const Tech = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [activeSection, setActiveSection] = useState('languageFrameworks');

    // Slider settings
    const SLIDER_SETTINGS = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    // Button styles
    const buttonStyles = {
        fontWeight: 'bold',
        width: '400px',
        margin: 4,
    };

    // Primary colors used
    const COLORS = {
        mainBlue: '#075DD1',
        textPrimary: '#030519',
        textSecondary: '#182759',
        backgroundGray: '#E6E6E6',
    };

    // Function to render a single category item
    const renderCategoryItem = (category, dataType) => (
        <Grid container spacing={2} key={category.name} sx={{ mb: 6 }} alignItems="center">
            <Grid item xs={12} md={3}>
                <Paper elevation={4} sx={{ p: 3, textAlign: 'center', height: '100%' }}>
                    <Box sx={{ fontSize: '3rem', color: COLORS.mainBlue, mb: 2 }}>
                        {React.cloneElement(category.icon, { fontSize: '4rem' })}
                    </Box>
                    <Link
                        href={category.link}
                        color="inherit"
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: COLORS.textPrimary }}>
                            {category.name}
                        </Typography>
                    </Link>
                    <Typography variant="subtitle1" sx={{ color: COLORS.textSecondary, fontStyle: 'italic' }}>
                        {category.description}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={9}>
                <Slider {...SLIDER_SETTINGS}>
                    {category[dataType].map((service) => (
                        <Box key={service.name}>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 4,
                                    textAlign: 'center',
                                    minHeight: '130px',
                                    margin: 1,
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                }}
                            >
                                <Box sx={{ fontSize: '3rem', color: COLORS.mainBlue, mb: 2 }}>
                                    {React.cloneElement(service.icon || service.icons, { fontSize: '4rem' })}
                                </Box>
                                <Link
                                    href={service.link}
                                    color={COLORS.textPrimary}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: COLORS.textPrimary }}>
                                        {service.name}
                                    </Typography>
                                </Link>
                                <Typography variant="body2" sx={{ color: COLORS.textSecondary, mt: 1 }}>
                                    {service.description}
                                </Typography>
                            </Paper>
                        </Box>
                    ))}
                </Slider>
            </Grid>
        </Grid>
    );

    // Main section rendering function
    const renderSection = (data, dataType) => (
        <Box sx={{ py: 10, px: 10, backgroundColor: COLORS.backgroundGray }}>
            <Grid container spacing={5} justifyContent="center">
                {data.map((category) => renderCategoryItem(category, dataType))}
            </Grid>
        </Box>
    );

    return (
        <>
            <Helmet>
                <title>Our Tech Stack | Atomic System InfoTech Solutions</title>
                <meta name="description" content="Explore the technologies that power our solutions and keep us at the forefront of innovation." />
                <meta name="keywords" content="React, Technology Stack, Web Development, Mobile Development, Tools, Frameworks" />
                <meta property="og:title" content="Our Tech Stack" />
                <meta property="og:description" content="Explore the technologies that power our solutions and keep us at the forefront of innovation." />
                <meta property="og:url" content="https://atomicsysitsolutions.com/tech-stack" />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <Banner
                title="Our Tech Stack"
                subtitle="Explore the technologies that power our solutions and keep us at the forefront of innovation."
                buttonText="Book Free 15 Minutes Consultation Meeting"
            />

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, backgroundColor: COLORS.backgroundGray }}>
                <Button
                    variant={activeSection === 'languageFrameworks' ? 'contained' : 'outlined'}
                    onClick={() => setActiveSection('languageFrameworks')}
                    sx={buttonStyles}
                >
                    Language Frameworks
                </Button>
                <Button
                    variant={activeSection === 'toolsDomain' ? 'contained' : 'outlined'}
                    onClick={() => setActiveSection('toolsDomain')}
                    sx={buttonStyles}
                >
                    Tools Domain
                </Button>
            </Box>

            {activeSection === 'languageFrameworks' && renderSection(languageFrameworks, 'frameworks')}
            {activeSection === 'toolsDomain' && renderSection(toolsDomain, 'tools')}
        </>
    );
};

export default Tech;

