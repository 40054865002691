import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from "react-helmet"; // For dynamic metadata

import Navbar from './components/NavBar';
import Footer from "./components/Footer";

import Home from './pages/Home';
import Products from './pages/Products';
import Services from './pages/Services';
import TechStack from "./pages/TechStack";
import OurProcess from "./pages/OurProcess";
import Career from "./pages/Careers";
import Contact from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";

function App() {
    return (
        <Router>
            {/* Global metadata for SEO */}
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Atomic System InfoTech Solutions</title>
                <meta name="description" content="Explore our innovative solutions, products, and services tailored to drive success." />
                <meta name="keywords" content="React, SEO, Web Development, Services, Products, Technology Stack" />
                <meta name="author" content="Atomic System Infotech Solutions" />
            </Helmet>

            {/* Navbar is visible on all pages */}
            <Navbar />

            {/* Define the routes */}
            <Routes>
                {/* Route for Home page */}
                <Route
                    path="/"
                    element={
                        <>
                            <Helmet>
                                <title>Home - Atomic System InfoTech Solutions</title>
                                <meta name="description" content="Welcome to our homepage. Discover how we can empower your business with technology." />
                            </Helmet>
                            <Home />
                        </>
                    }
                />

                {/* Route for About Us page */}
                <Route
                    path="/about-us"
                    element={
                        <>
                            <Helmet>
                                <title>About Us - Atomic System InfoTech Solutions</title>
                                <meta name="description" content="Learn more about our company, mission, and values." />
                            </Helmet>
                            <AboutUs />
                        </>
                    }
                />

                {/* Route for Products page */}
                <Route
                    path="/products"
                    element={
                        <>
                            <Helmet>
                                <title>Products - Atomic System InfoTech Solutions</title>
                                <meta name="description" content="Browse our innovative products designed for your business needs." />
                            </Helmet>
                            <Products />
                        </>
                    }
                />

                {/* Route for Services page */}
                <Route
                    path="/services"
                    element={
                        <>
                            <Helmet>
                                <title>Services - Atomic System InfoTech Solutions</title>
                                <meta name="description" content="Explore our range of services, including web development, mobile development, and more." />
                            </Helmet>
                            <Services />
                        </>
                    }
                />

                {/* Route for Tech Stack page */}
                <Route
                    path="/tech-stack"
                    element={
                        <>
                            <Helmet>
                                <title>Tech Stack - Atomic System InfoTech Solutions</title>
                                <meta name="description" content="Discover the technologies we use to build cutting-edge solutions." />
                            </Helmet>
                            <TechStack />
                        </>
                    }
                />

                {/* Route for Our Process page */}
                <Route
                    path="/process"
                    element={
                        <>
                            <Helmet>
                                <title>Our Process - Atomic System InfoTech Solutions</title>
                                <meta name="description" content="Learn about our streamlined process for delivering success." />
                            </Helmet>
                            <OurProcess />
                        </>
                    }
                />

                {/* Route for Careers page */}
                <Route
                    path="/careers"
                    element={
                        <>
                            <Helmet>
                                <title>Careers - Atomic System InfoTech Solutions</title>
                                <meta name="description" content="Join our team and build a rewarding career with us." />
                            </Helmet>
                            <Career />
                        </>
                    }
                />

                {/* Route for Contact Us page */}
                <Route
                    path="/contact"
                    element={
                        <>
                            <Helmet>
                                <title>Contact Us - Atomic System InfoTech Solutions</title>
                                <meta name="description" content="Get in touch with us for inquiries and collaborations." />
                            </Helmet>
                            <Contact />
                        </>
                    }
                />
            </Routes>

            {/* Footer visible on all pages */}
            <Footer />
        </Router>
    );
}

export default App;
