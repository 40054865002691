import React from 'react';
import { Box, Typography, Grid, Paper, Avatar } from '@mui/material';
import { Security, ThumbUp, Lightbulb, People, BusinessCenter, Web, CheckCircle } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Banner from "../components/Banner";

// Custom theme
const theme = createTheme({
    typography: {
        fontFamily: "Playfair Display, sans-serif",
    },
});

// Core Values Data
const values = [
    { icon: <Security fontSize="large" color="primary" />, title: "Integrity", description: "Upholding honesty and transparency in all actions." },
    { icon: <ThumbUp fontSize="large" color="primary" />, title: "Quality", description: "Delivering exceptional solutions that exceed expectations." },
    { icon: <Lightbulb fontSize="large" color="primary" />, title: "Innovation", description: "Bringing fresh ideas and creativity to projects." },
    { icon: <People fontSize="large" color="primary" />, title: "Collaboration", description: "Fostering teamwork for unparalleled success." },
];

// Leadership Data
const leaders = [
    {
        name: "Wajahat Bhatti",
        role: "Founder",
        bio: "Tech visionary passionate about leveraging emerging technologies for growth.",
    },
    {
        name: "Adeena Hussain",
        role: "Co-Founder",
        bio: "Dynamic leader driving impactful change with innovative strategies.",
    },
];

// Why Choose Us Data
const reasons = [
    { title: "Expert Team", description: "Skilled professionals tackling challenges effectively.", icon: <People sx={{ fontSize: 50, color: "#057DD1" }} /> },
    { title: "Quality Service", description: "High standards ensured through rigorous processes.", icon: <CheckCircle sx={{ fontSize: 50, color: "#057DD1" }} /> },
    { title: "Client Satisfaction", description: "Building trust with transparent communication.", icon: <ThumbUp sx={{ fontSize: 50, color: "#057DD1" }} /> },
    { title: "Innovative Solutions", description: "Tailored solutions using advanced tools.", icon: <Lightbulb sx={{ fontSize: 50, color: "#057DD1" }} /> },
];

const AboutUs = () => (
    <ThemeProvider theme={theme}>
        <>
            <Banner
                title="Who We Are"
                subtitle="Driving innovation and delivering excellence across industries. Learn about our journey, values, and commitment to quality."
                buttonText="Book Free 15 Minutes Consultation Meeting"
            />

            {/* About Us Section */}
            <Box sx={{ py: 6, px: 4, backgroundColor: "#E6E6E6", color: "#030519" }}>
                <Typography variant="h4" align="center" fontWeight="bold" mb={4}>
                    About Us
                </Typography>
                <Typography
                    variant="body1"
                    align="center"
                    sx={{ maxWidth: "700px", mx: "auto", mb: 6, lineHeight: 1.75 }}
                >
                    At Atomic System InfoTech Solutions, we are committed to delivering innovative technology solutions that drive success and empower businesses globally.
                </Typography>

                {/* Mission and Vision Section */}
                <Grid container spacing={4} justifyContent="center">
                    {[
                        {
                            title: "Our Mission",
                            description: "To deliver cutting-edge solutions that transform businesses.",
                            icon: <BusinessCenter sx={{ fontSize: 60, color: "#075DD1" }} />,
                        },
                        {
                            title: "Our Vision",
                            description: "To be a global leader in IT solutions, enhancing businesses.",
                            icon: <Web sx={{ fontSize: 60, color: "#075DD1" }} />,
                        },
                    ].map((item, index) => (
                        <Grid item xs={12} md={5} key={index}>
                            <Paper elevation={3} sx={{ p: 5, textAlign: "center", backgroundColor: "#FFFFFF", borderRadius: 2 }}>
                                {item.icon}
                                <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
                                    {item.title}
                                </Typography>
                                <Typography variant="body1" sx={{ color: "#182759", lineHeight: 1.6 }}>
                                    {item.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Core Values Section */}
            <Box sx={{ px: 4, py: 6, backgroundColor: "#E6E6E6" }}>
                <Typography variant="h4" align="center" fontWeight="bold" mb={6}>
                    Our Core Values
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {values.map((value, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Paper sx={{ p: 4, textAlign: "center", boxShadow: 3, backgroundColor: "#FFFFFF", borderRadius: 2 }}>
                                <Box sx={{ mb: 2 }}>{value.icon}</Box>
                                <Typography variant="h6" fontWeight="bold">
                                    {value.title}
                                </Typography>
                                <Typography variant="body2" sx={{ color: "#182759", lineHeight: 1.6 }}>
                                    {value.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Leadership Section */}
            <Box sx={{ py: 4, px: 2, backgroundColor: "#E6E6E6" }}>
                <Typography variant="h4" align="center" fontWeight="bold" mb={5}>
                    Leadership
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {leaders.map((leader, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper sx={{ p: 4, textAlign: "center", boxShadow: 3, borderRadius: 2 }}>
                                <Avatar alt={leader.name} sx={{ width: 120, height: 120, mx: "auto", mb: 3, border: "2px solid #075DD1" }} />
                                <Typography variant="h6" fontWeight="bold">
                                    {leader.name}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ color: "#075DD1", mb: 1 }}>
                                    {leader.role}
                                </Typography>
                                <Typography variant="body2" sx={{ color: "#182759", lineHeight: 1.6 }}>
                                    {leader.bio}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Why Choose Us Section */}
            <Box sx={{ py: 4, px: 2, backgroundColor: "#E6E6E6" }}>
                <Typography variant="h4" align="center" fontWeight="bold" mb={4}>
                    Why Choose Us
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {reasons.map((reason, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Paper sx={{ p: 4, textAlign: "center", boxShadow: 3, backgroundColor: "#FFFFFF", borderRadius: 2 }}>
                                <Box sx={{ mb: 2 }}>{reason.icon}</Box>
                                <Typography variant="h6" fontWeight="bold">
                                    {reason.title}
                                </Typography>
                                <Typography variant="body2" sx={{ color: "#182759", lineHeight: 1.6 }}>
                                    {reason.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    </ThemeProvider>
);

export default AboutUs;
