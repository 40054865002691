// ProductOverview.js
import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { FaHome, FaShoppingCart, FaBrain, FaChartBar, FaCloud } from 'react-icons/fa';
import { FaPeopleGroup, FaMessage } from 'react-icons/fa6';
import WebIcon from '@mui/icons-material/Web';
import {useNavigate} from "react-router-dom";

const productCategories = [
    {
        name: 'E-Commerce',
        icon: <FaShoppingCart aria-label="E-Commerce Icon" />,
        description:
            'Versatile e-commerce platforms for managing online stores, handling inventory, and processing payments.',
    },
    {
        name: 'ERP',
        icon: <FaHome aria-label="ERP Icon" />,
        description:
            'Enterprise Resource Planning tools for business management, including HR, finance, and supply chain modules.',
    },
    {
        name: 'CMS Platforms',
        icon: <WebIcon aria-label="CMS Platforms Icon" />,
        description:
            'Content Management Systems for managing and publishing website content without extensive coding.',
    },
    {
        name: 'Data Analytics and BI',
        icon: <FaChartBar aria-label="Data Analytics and BI Icon" />,
        description:
            'Business Intelligence tools for analyzing data, creating visualizations, and generating actionable insights.',
    },
    {
        name: 'CRM Solutions',
        icon: <FaPeopleGroup aria-label="CRM Solutions Icon" />,
        description:
            'Customer Relationship Management platforms to manage interactions, track leads, and optimize customer service.',
    },
    {
        name: 'Collaboration',
        icon: <FaMessage aria-label="Collaboration Icon" />,
        description:
            'Tools for real-time communication and collaboration, enabling teams to work together effectively.',
    },
    {
        name: 'Cloud Management',
        icon: <FaCloud aria-label="Cloud Management Icon" />,
        description:
            'Platforms for managing cloud infrastructure, virtual machines, and containers in hybrid and multi-cloud environments.',
    },
    {
        name: 'Machine Learning',
        icon: <FaBrain aria-label="Machine Learning Icon" />,
        description:
            'Platforms and frameworks for building, training, and deploying machine learning models and AI applications.',
    },
];

const ProductOverview = () => {
    const navigate = useNavigate(); // React Router navigation hook

    return (
        <>
            <Box
                sx={{
                    py: 6,
                    px: 4,
                    backgroundColor: '#E6E6E6',
                    fontFamily: 'Playwrite New Zealand',
                }}
            >
                <Typography
                    component="h2"
                    variant="h4"
                    sx={{
                        textAlign: 'center',
                        mb: 4,
                        fontWeight: 'bold',
                        color: '#030519',
                    }}
                >
                    Our Products
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {productCategories.map((category, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 3,
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: '0 10px 20px rgba(0,0,0,0.15)',
                                    },
                                }}
                                onClick={() => navigate('/products')} // Navigate to category link
                            >
                                <Typography sx={{ fontSize: 40, color: '#057DD1' }}>
                                    {category.icon}
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519', mt: 2 }}>
                                    {category.name}
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 1, color: '#182759' }}>
                                    {category.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default ProductOverview;
