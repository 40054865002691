// FrameworkCarousel.js
import React from 'react';
import Slider from 'react-slick';
import { SiActix, SiAndroid, SiAngular, SiAnsible, SiApache, SiBlazor, SiCodeigniter, SiDbt, SiDjango, SiExpress,
    SiFastapi, SiFlask, SiFlutter, SiFlux, SiGin, SiHibernate, SiKtor, SiKubernetes, SiLaravel, SiNestjs,
    SiNextdotjs, SiPandas, SiPresto, SiPytorch, SiQuarkus, SiReact, SiRemix, SiRocket, SiSpringboot, SiSvelte,
    SiSwift, SiSymfony, SiTensorflow, SiTide, SiUnity, SiVapor, SiVuedotjs, SiXamarin } from "react-icons/si";
import {CarouselSettings} from '../theme';

export const frameworksAndLibraries = [
    // Python
    { name: 'Django', icon: <SiDjango />, description: 'A high-level Python framework for fast, secure web app development.', link: 'https://www.geeksforgeeks.org/django-tutorial/?ref=header_outind' },
    { name: 'Flask', icon: <SiFlask />, description: 'A lightweight, flexible web framework in Python for rapid development.', link: 'https://www.geeksforgeeks.org/flask-tutorial/?ref=header_outind' },
    { name: 'FastAPI', icon: <SiFastapi />, description: 'A high-performance Python framework for modern, fast API development.', link: 'https://www.geeksforgeeks.org/fastapi-introduction/?ref=header_outind' },
    { name: 'TensorFlow', icon: <SiTensorflow />, description: 'An open-source ML and AI framework, widely used for deep learning.', link: 'https://www.geeksforgeeks.org/introduction-to-tensorflow/?ref=header_outind' },
    { name: 'PyTorch', icon: <SiPytorch />, description: 'A flexible, open-source library for machine learning and deep learning.', link: 'https://www.geeksforgeeks.org/getting-started-with-pytorch/?ref=header_outind' },
    { name: 'Pandas', icon: <SiPandas />, description: 'A Python library for data analysis with high-performance data structures.', link: 'https://www.geeksforgeeks.org/pandas-tutorial/?ref=header_outind' },

    // JavaScript
    { name: 'React', icon: <SiReact />, description: 'A library for building UI components, mainly for single-page web apps.', link: 'https://www.geeksforgeeks.org/react/?ref=header_outind' },
    { name: 'Vue.js', icon: <SiVuedotjs />, description: 'A progressive framework for building UIs, known for simplicity.', link: 'https://www.geeksforgeeks.org/vue-js/?ref=header_outind' },
    { name: 'Angular', icon: <SiAngular />, description: 'A TypeScript-based framework for building dynamic single-page apps.', link: 'https://www.geeksforgeeks.org/introduction-to-angularjs/?ref=header_outind' },
    { name: 'Next.js', icon: <SiNextdotjs />, description: 'A React framework for building fast, optimized web applications.', link: 'https://www.geeksforgeeks.org/nextjs/?ref=header_outind' },
    { name: 'Express.js', icon: <SiExpress />, description: 'A web framework for Node.js, widely used to build APIs and apps.', link: 'https://www.geeksforgeeks.org/express-js/?ref=header_outind' },
    { name: 'Svelte', icon: <SiSvelte />, description: 'A UI framework that compiles components to highly efficient JavaScript.', link: 'https://www.geeksforgeeks.org/svelte-introduction-and-installation/?ref=header_outind' },

    // TypeScript
    { name: 'NestJS', icon: <SiNestjs />, description: 'A progressive framework for scalable and efficient server-side apps.', link: 'https://www.geeksforgeeks.org/nestjs/?ref=header_outind' },
    { name: 'Next.js (TypeScript)', icon: <SiNextdotjs />, description: 'A React framework with TypeScript support for quick, smooth apps.', link: 'https://www.geeksforgeeks.org/nextjs/?ref=header_outind' },
    { name: 'Angular (TypeScript)', icon: <SiAngular />, description: 'A TypeScript-based framework for scalable, maintainable web apps.', link: 'https://www.geeksforgeeks.org/introduction-to-angularjs/?ref=header_outind' },
    { name: 'Remix', icon: <SiRemix />, description: 'A fast framework for creating modern web applications with React.', link: 'https://www.geeksforgeeks.org/remix-introduction-installation/?ref=header_outind' },

    // Java
    { name: 'Spring Boot', icon: <SiSpringboot />, description: 'A framework for building Java apps with embedded servers, no XML.', link: 'https://www.geeksforgeeks.org/spring-boot/?ref=header_outind' },
    { name: 'Hibernate', icon: <SiHibernate />, description: 'A tool for mapping Java objects to relational databases with ease.', link: 'https://www.geeksforgeeks.org/introduction-to-hibernate-framework/?ref=header_outind' },
    { name: 'Apache Struts', icon: <SiApache />, description: 'A Java framework for building web apps using the MVC architecture.', link: 'https://www.geeksforgeeks.org/introduction-and-working-of-struts-web-framework/?ref=header_outind' },
    { name: 'Quarkus', icon: <SiQuarkus />, description: 'A Java framework optimized for cloud-native apps, using GraalVM.', link: 'https://www.geeksforgeeks.org/difference-between-spring-boot-and-quarkus/?ref=header_outind' },

    // Kotlin
    { name: 'Ktor', icon: <SiKtor />, description: 'A flexible, asynchronous Kotlin framework for building web apps.', link: 'https://www.geeksforgeeks.org/kotlin-libraries/?ref=header_outind' },
    { name: 'Spring Boot (Kotlin)', icon: <SiSpringboot />, description: 'A Kotlin-friendly Spring Boot for easy backend app development.', link: 'https://www.geeksforgeeks.org/kotlin-libraries/?ref=header_outind' },
    { name: 'Android Architecture Components', icon: <SiAndroid />, description: 'Libraries to help design maintainable, robust Android apps.', link: 'https://www.geeksforgeeks.org/kotlin-libraries/?ref=header_outind' },

    // Swift
    { name: 'SwiftUI', icon: <SiSwift />, description: 'A declarative framework for building native UIs across Apple devices.', link: 'https://www.geeksforgeeks.org/swift-tutorial/?ref=header_outind' },
    { name: 'Vapor', icon: <SiVapor />, description: 'A server-side Swift framework for building fast, secure web apps.', link: 'https://www.geeksforgeeks.org/swift-tutorial/?ref=header_outind' },

    // Rust
    { name: 'Rocket', icon: <SiRocket />, description: 'A Rust web framework focused on safety, speed, and simplicity.', link: 'https://www.geeksforgeeks.org/top-rust-libraries/?ref=header_outind' },
    { name: 'Actix', icon: <SiActix />, description: 'A Rust framework for fast, scalable, actor-based web apps.', link: 'https://www.geeksforgeeks.org/top-rust-libraries/?ref=header_outind' },
    { name: 'Tide', icon: <SiTide />, description: 'A minimal Rust framework designed for async, rapid web development.', link: 'https://www.geeksforgeeks.org/top-rust-libraries/?ref=header_outind' },

    // Go (Golang)
    { name: 'Gin', icon: <SiGin />, description: 'A minimal, efficient Go framework for fast, scalable web applications.', link: 'https://www.geeksforgeeks.org/top-golang-frameworks/?ref=header_outind' },

    // Ruby
    // { name: 'Ruby on Rails', icon: <SiRails />, description: 'A web framework emphasizing convention over configuration, using Ruby.' },

    // PHP
    { name: 'Laravel', icon: <SiLaravel />, description: 'A PHP framework with elegant syntax for modern, scalable web apps.', link: 'https://www.geeksforgeeks.org/introduction-to-laravel-and-mvc-framework/?ref=header_outind' },
    { name: 'Symfony', icon: <SiSymfony />, description: 'A flexible PHP framework for building robust, large web applications.', link: 'https://www.geeksforgeeks.org/installation-and-configuration-symfony-framework/?ref=header_outind' },
    { name: 'CodeIgniter', icon: <SiCodeigniter />, description: 'A PHP framework for lightweight, rapid application development.', link: 'https://www.geeksforgeeks.org/introduction-to-codeignitor-php/?ref=header_outind' },

    // SQL
    { name: 'Apache Spark SQL', icon: <SiApache />, description: 'A framework for distributed data processing, supporting SQL-based analysis.', link: 'https://www.geeksforgeeks.org/components-of-apache-spark/?ref=header_outind' },
    { name: 'Presto', icon: <SiPresto />, description: 'A distributed SQL engine for high-speed queries on large datasets.', link: 'https://www.geeksforgeeks.org/overview-of-apache-presto/?ref=header_outind' },
    { name: 'DBT', icon: <SiDbt />, description: 'A transformation framework for analysts, turning raw data into insights.', link: 'https://www.geeksforgeeks.org/what-is-dbt/?ref=header_outind' },

    // Dart
    { name: 'Flutter', icon: <SiFlutter />, description: 'A toolkit for building native apps across mobile, web, and desktop.', link: 'https://www.geeksforgeeks.org/flutter-tutorial/?ref=header_outind' },

    // C#
    { name: 'Unity', icon: <SiUnity />, description: 'A cross-platform engine for building 2D/3D games and simulations.', link: 'https://www.geeksforgeeks.org/unity-introduction-to-interface/?ref=header_outind' },
    { name: 'Blazor', icon: <SiBlazor />, description: 'A C# framework for building web applications without JavaScript.', link: 'https://www.geeksforgeeks.org/microsoft-azure-blazor-and-azure-functions-for-serverless-websites/?ref=header_outind' },
    { name: 'Xamarin', icon: <SiXamarin />, description: 'A framework for building cross-platform mobile apps using C#.', link: 'https://www.geeksforgeeks.org/introduction-to-xamarin-a-software-for-mobile-app-development-and-app-creation/?ref=header_outind' },

    // Shell Scripting (Bash)
    { name: 'Ansible', icon: <SiAnsible />, description: 'An IT automation tool for configuration management and deployment.', link: 'https://www.geeksforgeeks.org/ansible-ad-hoc-commands/?ref=header_outind' },
    { name: 'Kubernetes Helm', icon: <SiKubernetes />, description: 'A package manager for Kubernetes applications for easy setup.', link: 'https://www.geeksforgeeks.org/kubernetes-tutorial/?ref=header_outind' },

    // Julia
    { name: 'Flux.jl', icon: <SiFlux />, description: 'A Julia library for machine learning, focused on flexibility and power.', link: 'https://www.geeksforgeeks.org/indroduction-in-deep-learning-with-julia/?ref=header_outind' },

    // Elixir
    // { name: 'Phoenix', icon: <SiPhoenix />, description: 'A web framework for Elixir, for building scalable, maintainable apps.' },

    // Haskell
    // { name: 'Yesod', icon: <SiYesod />, description: 'A Haskell web framework for secure, maintainable applications.' }
];

const FrameworkCarousel = () => {
    return (
        <>
            <div className="horizontal-slider" style={{ fontFamily: 'Playwrite New Zealand' }}>
                <Slider {...CarouselSettings}>
                    {frameworksAndLibraries.map((framework, index) => (
                        <div key={index} className="slide">
                            <a href={framework.link} className="slide" target="_blank" rel="noopener noreferrer">
                                <div className="slide-icon">{framework.icon}</div>
                                <div className="slide-name">{framework.name}</div>
                            </a>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default FrameworkCarousel;