// ServicesCarousel.js
import React from 'react';
import Slider from 'react-slick';
import { FaCode, FaLaptopCode, FaPaintBrush, FaHeadset, FaCloud, FaShieldAlt, FaShoppingCart, FaCogs } from 'react-icons/fa';
import {CarouselSettings} from '../theme';

const services = [
    { name: 'Development Services', icon: <FaCode />, link: 'https://atomicsysitsolutions.com/services' },
    { name: 'Emerging Technologies', icon: <FaLaptopCode />, link: 'https://atomicsysitsolutions.com/services' },
    { name: 'Design and UX', icon: <FaPaintBrush />, link: 'https://atomicsysitsolutions.com/services' },
    { name: 'IT Support', icon: <FaHeadset />, link: 'https://atomicsysitsolutions.com/services' },
    { name: 'Cloud Consulting', icon: <FaCloud />, link: 'https://atomicsysitsolutions.com/services' },
    { name: 'Cybersecurity', icon: <FaShieldAlt />, link: 'https://atomicsysitsolutions.com/services' },
    { name: 'eCommerce & Marketing', icon: <FaShoppingCart />, link: 'https://atomicsysitsolutions.com/services' },
    { name: 'DevOps & Automation', icon: <FaCogs />, link: 'https://atomicsysitsolutions.com/services' },
];

const ServiceCarousel = () => {
    return (
        <>
            <div className="horizontal-slider" style={{ fontFamily: 'Playwrite New Zealand' }}>
                <Slider {...CarouselSettings}>
                    {services.map((service, index) => (
                        <div key={index} className="slide">
                            <a href={service.link} className="slide" target="_blank" rel="noopener noreferrer">
                                <div className="slide-icon">{service.icon}</div>
                                <div className="slide-name">{service.name}</div>
                            </a>
                        </div>
                        ))}
                </Slider>
            </div>
        </>
    );
}

export default ServiceCarousel;
