import { keyframes } from '@mui/system';

export const CarouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {breakpoint: 1024, settings: {slidesToShow: 4}},
        {breakpoint: 768, settings: {slidesToShow: 3}},
        {breakpoint: 480, settings: {slidesToShow: 2}},
    ],
};

// Keyframes for gradient animation
export const gradientAnimation = keyframes`
    0% { background-position: 0 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0 50%; }
`;

// Styles moved to objects for reusability and separation
export const bannerStyles = {
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    color: '#E6E6E6',
    background: 'linear-gradient(120deg, #182759, #075DD1, #182759)',
    backgroundSize: '300% 300%',
    animation: `${gradientAnimation} 15s ease infinite`,
};

export const bannerTitleStyles = {
    fontWeight: 'bold',
    fontFamily: 'Playwright New Zealand',
    fontSize: { xs: '1.8rem', sm: '2.4rem', md: '3rem' },
    mb: 2,
    textShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
};

export const bannerSubtitleStyles = {
    fontFamily: 'Playwright New Zealand',
    fontSize: { xs: '1rem', sm: '1.25rem', md: '1.75rem' },
    maxWidth: { xs: '90%', md: '800px' },
    mb: 4,
    opacity: 0.9,
};

export const bannerButtonStyles = {
    padding: { xs: '0.6rem 1.5rem', md: '0.8rem 2rem' },
    fontSize: { xs: '1rem', md: '1.2rem' },
    fontFamily: 'Playwright New Zealand',
    backgroundColor: '#030519',
    '&:hover': { backgroundColor: '#075DD1' },
    borderRadius: '50px',
    textTransform: 'none',
    boxShadow: '0px 10px 20px rgba(0, 128, 255, 0.3)',
};