// ToolsCarousel.js
import React from 'react';
import Slider from 'react-slick';
import { SiAdobe, SiAndroidstudio, SiApachespark, SiAzuredevops, SiBitbucket, SiCanva, SiCircleci, SiCypress, SiDatadog,
         SiDigitalocean, SiDocker, SiElasticsearch, SiFigma, SiFirebase, SiGithub, SiGithubactions, SiGitlab, SiGrafana,
         SiHeroku, SiIntellijidea, SiInvision, SiJenkins, SiJest, SiJira, SiJupyter, SiKubernetes, SiMongodb, SiMysql,
         SiNetlify, SiNewrelic, SiPandas, SiPostgresql, SiPostman, SiPrisma, SiPrometheus, SiPycharm, SiPytest,
         SiPytorch, SiRedis, SiReplit, SiSelenium, SiSentry, SiSketch, SiSlack, SiSplunk, SiSqlalchemy, SiTableau,
         SiTeamcity, SiTensorflow, SiTravisci, SiTrello, SiVercel, SiVisualstudiocode, SiXcode } from "react-icons/si";
import {FaAws, FaGithub} from "react-icons/fa";
import {CarouselSettings} from '../theme';

export const tools = [
    { name: "Figma", icons: <SiFigma />, description: "Design and prototype tool for collaborative UI/UX workflows in cloud.", link: 'https://www.figma.com/' },
    { name: "Adobe XD", icons: <SiAdobe />, description: "A design tool for creating web and mobile prototypes with Adobe integration.", link: 'https://www.adobe.com/'  },
    { name: "Sketch", icons: <SiSketch />, description: "Digital design software for web and mobile interfaces with plugins and ease.", link: 'https://www.sketchup.com/en'  },
    { name: "InVision", icons: <SiInvision />, description: "Product design platform enabling collaborative workflows for UI/UX teams.", link: 'https://appsource.microsoft.com/en-us/product/office/WA200003579'  },
    { name: "Canva", icons: <SiCanva />, description: "Web-based tool with templates for creating social media and graphics easily.", link: 'https://www.canva.com/'  },

    { name: "Visual Studio Code", icons: <SiVisualstudiocode />, description: "Powerful editor with debugging, Git, and multiple language support extensions.", link: 'https://code.visualstudio.com/' },
    { name: "IntelliJ IDEA", icons: <SiIntellijidea />, description: "Java IDE for JVM languages with robust debugging and code completion.", link: 'https://www.jetbrains.com/idea/'  },
    { name: "PyCharm", icons: <SiPycharm />, description: "IDE for Python with code assistance, debugging, and Django support.", link: 'https://www.jetbrains.com/pycharm/'  },
    { name: "Android Studio", icons: <SiAndroidstudio />, description: "IDE for Android app development with tools for coding, testing, and debugging.", link: 'https://developer.android.com/studio'  },
    { name: "Xcode", icons: <SiXcode />, description: "IDE for macOS/iOS with powerful debugging and testing for app development.", link: 'https://developer.apple.com/xcode/'  },
    { name: "Repl.it", icons: <SiReplit />, description: "Online platform to write, run, and share code in various languages in-browser.", link: 'https://replit.com/'  },
    { name: "GitHub Codespaces", icons: <SiGithub />, description: "Browser-based dev environment in GitHub for writing, testing, and debugging.", link: 'https://github.com/features/codespaces'  },

    { name: "GitHub", icons: <FaGithub />, description: "Platform for Git version control, code management, and team collaboration.", link: 'https://github.com/'  },
    { name: "GitLab", icons: <SiGitlab />, description: "Git repository and DevOps platform with CI/CD and project tracking tools.", link: 'https://gitlab.com/gitlab-org/gitlab'  },
    { name: "Bitbucket", icons: <SiBitbucket />, description: "Git version control platform that integrates with Jira for project workflows.", link: 'https://bitbucket.org/'  },
    { name: "JIRA", icons: <SiJira />, description: "Agile project management tool with issue tracking, sprints, and workflow.", link: 'https://www.atlassian.com/software/jira'  },
    { name: "Trello", icons: <SiTrello />, description: "Visual project management tool with boards, lists, and cards for tasks.", link: 'https://trello.com/'  },
    { name: "Slack", icons: <SiSlack />, description: "Team messaging platform with channels, DMs, and tool integrations.", link: 'https://slack.com/'  },

    { name: "Docker", icons: <SiDocker />, description: "Tool for building, deploying, and managing applications in containers.", link: 'https://www.docker.com/'  },
    { name: "Kubernetes", icons: <SiKubernetes />, description: "Platform for automated deployment, scaling, and management of containers.", link: 'https://kubernetes.io/'  },
    { name: "Heroku", icons: <SiHeroku />, description: "PaaS for building, running, and scaling apps with managed runtime services.", link: 'https://www.heroku.com/'  },
    { name: "AWS Elastic Beanstalk", icons: <FaAws />, description: "Managed service for deploying, running, and scaling applications on AWS.", link: 'https://aws.amazon.com/elasticbeanstalk/?trk=8e98a4bf-1153-4bca-8e0a-d26826bc487e&sc_channel=ps&s_kwcid=AL!4422!10!71674650271948!71675176766112&ef_id=353aa5d459ac1399a8f1c258271f6a07:G:s&msclkid=353aa5d459ac1399a8f1c258271f6a07'  },
    { name: "Netlify", icons: <SiNetlify />, description: "Platform for deploying static sites and serverless apps with Git integration.", link: 'https://www.netlify.com/'  },
    { name: "Vercel", icons: <SiVercel />, description: "Platform for frontend developers to build and deploy sites and apps fast.", link: 'https://vercel.com/'  },
    { name: "Firebase", icons: <SiFirebase />, description: "Platform offering database, hosting, authentication, and cloud functions.", link: 'https://firebase.google.com/'  },
    { name: "DigitalOcean App Platform", icons: <SiDigitalocean />, description: "PaaS to build, deploy, and scale apps in multiple programming languages.", link: 'https://www.digitalocean.com/products/app-platform'  },

    { name: "GitHub Actions", icons: <SiGithubactions />, description: "CI/CD platform to automate workflows like tests and deployments on GitHub.", link: 'https://github.com/features/actions'  },
    { name: "Jenkins", icons: <SiJenkins />, description: "Open-source automation server for CI/CD with extensive plugin support.", link: 'https://www.jenkins.io/'  },
    { name: "CircleCI", icons: <SiCircleci />, description: "CI/CD platform to automate code builds, tests, and deployment processes.", link: 'https://circleci.com/docs/about-circleci/'  },
    { name: "Travis CI", icons: <SiTravisci />, description: "Cloud CI/CD tool for automating builds and tests for GitHub repositories.", link: 'https://www.travis-ci.com/'  },
    { name: "GitLab CI/CD", icons: <SiGitlab />, description: "CI/CD service in GitLab for automating tests, workflows, and deployments.", link: 'https://docs.gitlab.com/ee/ci/'  },
    { name: "TeamCity", icons: <SiTeamcity />, description: "CI/CD server by JetBrains with support for various languages and projects.", link: 'https://www.jetbrains.com/teamcity/'  },
    { name: "Azure DevOps", icons: <SiAzuredevops />, description: "Microsoft DevOps suite for CI/CD, version control, and project tracking.", link: 'https://azure.microsoft.com/en-us/products/devops/?msockid=38f76f6bb6a461c706a07b16b7ad607b'  },

    { name: "Selenium", icons: <SiSelenium />, description: "Framework for web automation, enabling user simulations for app testing.", link: 'https://www.selenium.dev/'  },
    { name: "Jest", icons: <SiJest />, description: "JS testing framework for unit and integration tests with mocking support.", link: 'https://jestjs.io/'  },
    { name: "Cypress", icons: <SiCypress />, description: "JavaScript end-to-end testing framework with live reloading and debug.", link: 'https://www.cypress.io/'  },
    { name: "PyTest", icons: <SiPytest />, description: "Python framework for scalable unit and integration testing automation.", link: 'https://pypi.org/project/pytest/'  },
    { name: "Postman", icons: <SiPostman />, description: "API development tool for sending requests and inspecting HTTP responses.", link: 'https://www.postman.com/'  },

    { name: "PostgreSQL", icons: <SiPostgresql />, description: "Reliable open-source SQL database for complex applications and extensibility.", link: 'https://www.postgresql.org/'  },
    { name: "MySQL", icons: <SiMysql />, description: "Open-source relational database for web apps with speed and flexibility.", link: 'https://www.mysql.com/'  },
    { name: "MongoDB", icons: <SiMongodb />, description: "NoSQL database for scalable, JSON-like data storage in web applications.", link: 'https://www.mongodb.com/?msockid=38f76f6bb6a461c706a07b16b7ad607b'  },
    { name: "Firebase Realtime Database", icons: <SiFirebase />, description: "Cloud-hosted NoSQL database syncing data in real-time for mobile/web.", link: 'https://firebase.google.com/docs/database/'  },
    { name: "Redis", icons: <SiRedis />, description: "In-memory key-value store used for caching, messaging, and real-time apps.", link: 'https://redis.io/'  },
    { name: "Prisma", icons: <SiPrisma />, description: "Toolkit for Node.js/TypeScript for easy database access and schema management.", link: 'https://www.prisma.io/'  },
    { name: "SQLAlchemy", icons: <SiSqlalchemy />, description: "Python SQL toolkit and ORM for database interaction and complex queries.", link: 'https://www.sqlalchemy.org/'  },

    { name: "Jupyter Notebook", icons: <SiJupyter />, description: "Interactive coding, visualizations, and narrative environment for data.", link: 'https://jupyter.org/'  },
    { name: "TensorFlow", icons: <SiTensorflow />, description: "Machine learning library for creating and deploying scalable ML models.", link: 'https://www.tensorflow.org/'  },
    { name: "PyTorch", icons: <SiPytorch />, description: "Python ML framework for rapid prototyping and deploying AI models.", link: 'https://pytorch.org/'  },
    { name: "Apache Spark", icons: <SiApachespark />, description: "Unified analytics engine for big data processing, ML, and stream tasks.", link: 'https://spark.apache.org/'  },
    { name: "Pandas", icons: <SiPandas />, description: "Data manipulation library for Python with DataFrames and analysis tools.", link: 'https://pandas.pydata.org/'  },
    { name: "Tableau", icons: <SiTableau />, description: "Visualization tool for interactive dashboards and business intelligence.", link: 'https://www.tableau.com/'  },

    { name: "Prometheus", icons: <SiPrometheus />, description: "Monitoring and time-series database system with powerful querying.", link: 'https://prometheus.io/docs/introduction/overview/'  },
    { name: "Grafana", icons: <SiGrafana />, description: "Analytics platform for visualizing time-series data with interactive dashboards.", link: 'https://grafana.com/'  },
    { name: "ELK Stack", icons: <SiElasticsearch />, description: "Centralized logging platform with Elasticsearch, Logstash, and Kibana.", link: 'https://www.elastic.co/elastic-stack/'  },
    { name: "New Relic", icons: <SiNewrelic />, description: "Platform for monitoring app performance and system metrics in real-time.", link: 'https://newrelic.com/'  },
    { name: "Datadog", icons: <SiDatadog />, description: "Monitoring and analytics platform for infrastructure and application logs.", link: 'https://www.datadoghq.com/'  },
    { name: "Sentry", icons: <SiSentry />, description: "Real-time error tracking tool to monitor and fix app crashes.", link: 'https://sentry.io/welcome/'  },
    { name: "Splunk", icons: <SiSplunk />, description: "Data platform for monitoring, analyzing, and visualizing machine data.", link: 'https://www.splunk.com/'  }
];

const ToolsCarousel = () => {
    return (
        <>
            <div className="horizontal-slider" style={{ fontFamily: 'Playwrite New Zealand' }}>
                <Slider {...CarouselSettings}>
                    {tools.map((tool, index) => (
                        <div key={index} className="slide">
                            <a href={tool.link} className="slide" target="_blank" rel="noopener noreferrer">
                                <div className="slide-icon">{tool.icons}</div>
                                <div className="slide-name">{tool.name}</div>
                            </a>
                        </div>
                        ))}
                </Slider>
            </div>
        </>
    );
};

export default ToolsCarousel;
