// LanguageCarousel.js
import React from 'react';
import Slider from 'react-slick';
import { FaPython, FaJava, FaJs, FaPhp, FaSwift, FaRust } from 'react-icons/fa';
import { SiTypescript, SiCsharp, SiKotlin, SiRuby, SiR, SiMysql, SiDart, SiScala, SiGnubash, SiJulia, SiElixir,
         SiHaskell } from 'react-icons/si';
import { FaGolang } from "react-icons/fa6";
import {CarouselSettings} from '../theme';

// Define the programming languages data
export const languages = [
    { name: 'Python', icon: <FaPython />, description: 'Popular for web, data science, ML, and automating tasks in development.', link: 'https://www.geeksforgeeks.org/python-programming-language-tutorial/?ref=header_outind' },
    { name: 'JavaScript', icon: <FaJs />, description: 'Vital for web front-end (React) and back-end (Node.js), widely adopted in tech.', link: 'https://www.geeksforgeeks.org/javascript/?ref=header_outind' },
    { name: 'TypeScript', icon: <SiTypescript />, description: 'JavaScript superset with static typing, favored in large-scale application dev.', link: 'https://www.geeksforgeeks.org/typescript/?ref=header_outind' },
    { name: 'Java', icon: <FaJava />, description: 'Dominates enterprise apps, backend, and Android; stable and widely implemented.', link: 'https://www.geeksforgeeks.org/java/?ref=header_outind' },
    { name: 'Kotlin', icon: <SiKotlin />, description: 'Android’s modern choice over Java; concise, safer, and easy-to-read language.', link: 'https://www.geeksforgeeks.org/kotlin-programming-language/?ref=header_outind' },
    { name: 'Swift', icon: <FaSwift />, description: 'Built for iOS/macOS development, Swift powers Apple apps with high performance.', link: 'https://www.geeksforgeeks.org/swift-tutorial/?ref=header_outind' },
    { name: 'Rust', icon: <FaRust />, description: 'Performance-focused with memory safety, used in system programming and beyond.', link: 'https://www.geeksforgeeks.org/introduction-to-rust-programming-language/?ref=header_outind' },
    { name: 'Go', icon: <FaGolang />, description: 'Google’s language for backend, microservices, and cloud with a simple syntax.', link: 'https://www.geeksforgeeks.org/golang/?ref=header_outind' },
    { name: 'Ruby', icon: <SiRuby />, description: 'Used with Rails for web dev, praised for its simplicity and productivity.', link: 'https://www.geeksforgeeks.org/ruby-programming-language/?ref=header_outind' },
    { name: 'PHP', icon: <FaPhp />, description: 'Important for CMSs like WordPress, PHP remains a web development staple.', link: 'https://www.geeksforgeeks.org/php-tutorial/?ref=header_outind' },
    { name: 'SQL', icon: <SiMysql />, description: 'Database language key for data management and analytics, not general-purpose.', link: 'https://www.geeksforgeeks.org/sql-tutorial/?ref=header_outind' },
    { name: 'R', icon: <SiR />, description: 'Focuses on stats, data science, and research; a mainstay in academic fields.', link: 'https://www.geeksforgeeks.org/r-tutorial/?ref=header_outind' },
    { name: 'Dart', icon: <SiDart />, description: 'Used with Flutter, Google’s framework for cross-platform mobile applications.', link: 'https://www.geeksforgeeks.org/dart-tutorial/?ref=header_outind' },
    { name: 'C#', icon: <SiCsharp />, description: 'Popular in enterprise, Windows, and game dev with Unity, a versatile language.', link: 'https://www.geeksforgeeks.org/csharp-programming-language/?ref=header_outind' },
    { name: 'Scala', icon: <SiScala />, description: 'Functional and object-oriented, used in data processing and Spark applications.', link: 'https://www.geeksforgeeks.org/scala-programming-language/?ref=header_outind' },
    { name: 'Shell scripting (Bash)', icon: <SiGnubash />, description: 'Vital for automating DevOps, managing systems, and command-line tasks.', link: 'https://www.geeksforgeeks.org/bash-scripting-introduction-to-bash-and-bash-scripting/?ref=header_outind' },
    { name: 'Julia', icon: <SiJulia />, description: 'Ideal for scientific computing, fast in high-performance computing and research.', link: 'https://www.geeksforgeeks.org/julia-language-introduction/?ref=header_outind' },
    { name: 'Elixir', icon: <SiElixir />, description: 'Known for concurrency, used in real-time, resilient apps like messaging systems.', link: 'https://www.geeksforgeeks.org/difference-between-elixir-and-erlang/?ref=header_outind' },
    { name: 'Haskell', icon: <SiHaskell />, description: 'Favored in academia, reliable and functional for critical application needs.', link: 'https://www.geeksforgeeks.org/what-is-haskell-programming-language/?ref=header_outind' },
];

const LanguagesCarousel = () => {
    return (
        <>
            <div className="horizontal-slider" style={{ fontFamily: 'Playwrite New Zealand' }}>
                <Slider {...CarouselSettings}>
                    {languages.map((language, index) => (
                        <div key={index} className="slide">
                            <a href={language.link} className="slide" target="_blank" rel="noopener noreferrer">
                                <div className="slide-icon">{language.icon}</div>
                                <div className="slide-name">{language.name}</div>
                            </a>
                        </div>
                        ))}
                </Slider>
            </div>
        </>
    );
};

export default LanguagesCarousel;
