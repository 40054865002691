// Services.js
import React from 'react';
import {Box, Typography, Grid, Paper, useMediaQuery, Button} from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import icons
import WebIcon from '@mui/icons-material/Web';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CodeIcon from '@mui/icons-material/Code';
import ApiIcon from '@mui/icons-material/Api';
import StoreIcon from '@mui/icons-material/Store';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import BlockchainIcon from '@mui/icons-material/DeviceUnknown'; // Placeholder, consider custom SVG for blockchain
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import SecurityIcon from '@mui/icons-material/Security';
import CloudIcon from '@mui/icons-material/Cloud';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import BuildIcon from '@mui/icons-material/Build';
import InsightsIcon from '@mui/icons-material/Insights';
import StorageIcon from '@mui/icons-material/Storage';
import LockIcon from '@mui/icons-material/Lock';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Banner from "../components/Banner";
import {Helmet} from "react-helmet";

const serviceCategories = [
    {
        name: 'Development Services',
        icon: <CodeIcon />,
        subheading: 'High-quality software solutions',
        services: [
            { name: 'Web Development', icon: <WebIcon />, description: 'Crafting responsive, optimized websites with modern technology.' },
            { name: 'Mobile App Development', icon: <PhoneIphoneIcon />, description: 'Building scalable mobile apps with smooth, engaging user experiences.' },
            { name: 'Custom Software Development', icon: <CodeIcon />, description: 'Creating bespoke solutions to address unique business challenges.' },
            { name: 'API Integration', icon: <ApiIcon />, description: 'Seamlessly connecting third-party APIs for data enhancement.' },
            { name: 'E-commerce Solutions', icon: <StoreIcon />, description: 'Building secure and efficient platforms for online sales.' },
        ],
    },
    {
        name: 'Emerging Technologies',
        icon: <SmartToyIcon />,
        subheading: 'Stay ahead with the latest tech',
        services: [
            { name: 'AI & ML', icon: <SmartToyIcon />, description: 'Automating processes and gaining insights with AI and ML.' },
            { name: 'IoT', icon: <DeviceHubIcon />, description: 'Developing IoT solutions for data connectivity and monitoring.' },
            { name: 'Blockchain', icon: <BlockchainIcon />, description: 'Implementing blockchain for secure, transparent transactions.' },
        ],
    },
    {
        name: 'Design and UX',
        icon: <DesignServicesIcon />,
        subheading: 'User-centric design services',
        services: [
            { name: 'UI/UX Design', icon: <DesignServicesIcon />, description: 'Creating appealing, user-friendly interfaces for engagement.' },
            { name: 'Responsive Web Design', icon: <WebIcon />, description: 'Designing adaptable sites for a seamless experience on all devices.' },
            { name: 'Mobile App UI Design', icon: <PhonelinkSetupIcon />, description: 'Crafting intuitive, visually attractive mobile app interfaces.' },
        ],
    },
    {
        name: 'IT Support and Consulting',
        icon: <SecurityIcon />,
        subheading: 'Comprehensive IT services for businesses',
        services: [
            { name: 'IT Strategy Consulting', icon: <InsightsIcon />, description: 'Aligning IT strategies with overall business objectives.' },
            { name: 'System Integration Services', icon: <IntegrationInstructionsIcon />, description: 'Improving efficiency through systems integration.' },
            { name: 'IT Infrastructure Support', icon: <BuildIcon />, description: 'Maintaining reliable and secure IT infrastructure.' },
            { name: 'Managed IT Services', icon: <SecurityIcon />, description: 'Ensuring optimal IT performance and security management.' },
        ],
    },
    {
        name: 'Cloud and Migration',
        icon: <CloudIcon />,
        subheading: 'Maximize cloud potential with AWS',
        services: [
            { name: 'Data and Analytics', icon: <InsightsIcon />, description: 'Turning data into insights for strategic decision-making.' },
            { name: 'Big Data Solutions', icon: <StorageIcon />, description: 'Processing and analyzing large data volumes efficiently.' },
            { name: 'Data Visualization and Reporting', icon: <TrendingUpIcon />, description: 'Providing clear insights through data visualizations.' },
            { name: 'Business Intelligence (BI)', icon: <TrendingUpIcon />, description: 'Enhancing business analysis with BI solutions.' },
            { name: 'Data Warehousing', icon: <StorageIcon />, description: 'Managing data storage efficiently and securely.' },
            { name: 'Hosting', icon: <CloudIcon />, description: 'Offering scalable cloud hosting for business growth.' },
        ],
    },
    {
        name: 'Cybersecurity',
        icon: <LockIcon />,
        subheading: 'Protect your business from digital threats',
        services: [
            { name: 'Network Security Solutions', icon: <LockIcon />, description: 'Securing network infrastructure against breaches.' },
            { name: 'Data Encryption and Protection', icon: <LockIcon />, description: 'Applying encryption for robust data security.' },
            { name: 'Threat Monitoring and Response', icon: <SecurityIcon />, description: 'Real-time threat detection and response.' },
            { name: 'Security Audits and Compliance', icon: <AccountTreeIcon />, description: 'Ensuring compliance with security standards.' },
            { name: 'Identity and Access Management', icon: <SecurityIcon />, description: 'Securing access with IAM solutions.' },
        ],
    },
];

const Services = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const sliderSettings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <>
            <Helmet>
                <title>IT Solutions | Transforming Ideas into Reality</title>
                <meta name="description" content="Comprehensive IT solutions tailored to meet your business goals. Book a free consultation now!" />
                <meta property="og:title" content="IT Solutions | Transforming Ideas into Reality" />
                <meta property="og:description" content="Comprehensive IT solutions tailored to meet your business goals. Book a free consultation now!" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://atomicsysitsolutions.com/services" />
            </Helmet>

            <Banner
                title={'Transforming Ideas into Reality'}
                subtitle={'Comprehensive IT solutions tailored to meet your business goals'}
                buttonText={'Book Free 15 Minutes Consultation Meeting'}
            />

            <Box sx={{ py: 10, px: 10, backgroundColor: '#E6E6E6' }}>
                <Grid container spacing={5} justifyContent="center">
                    {serviceCategories.map((category, index) => (
                        <Grid container spacing={2} key={index} sx={{ mb: 6 }} alignItems="center">
                            <Grid item xs={12} md={3}>
                                <Paper elevation={4} sx={{ p: 3, textAlign: 'center', height: '100%' }}>
                                    <Box sx={{ fontSize: '3rem', color: '#075DD1', mb: 2 }}>
                                        {React.cloneElement(category.icon, { fontSize: '4rem' })}
                                    </Box>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519' }}>
                                        {category.name}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ color: '#182759', fontStyle: 'italic' }}>
                                        {category.subheading}
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Slider {...sliderSettings}>
                                    {category.services.map((service, i) => (
                                        <Box key={i}>
                                            <Paper elevation={3} sx={{ p: 4, textAlign: 'center', minHeight: '130px', margin: 1, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)' }}>
                                                <Box sx={{ fontSize: '3rem', color: '#075DD1', mb: 2 }}>
                                                    {React.cloneElement(service.icon, { fontSize: '4rem' })}
                                                </Box>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519' }}>
                                                    {service.name}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#182759', mt: 1 }}>
                                                    {service.description}
                                                </Typography>
                                            </Paper>
                                        </Box>
                                    ))}
                                </Slider>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ textAlign: 'center', mt: 6 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        href="/process"
                        sx={{ py: 1.5, px: 4, fontSize: '1.1rem', transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.05)' } }}
                    >
                        CheckOut Our Proven Approach
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default Services;
