// Home.js
import React from 'react';
import { Helmet } from 'react-helmet'; // For managing metadata
import Banner from '../components/Banner';
import Introduction from "../components/home/AboutOverview";
import ServiceCarousel from "../components/carouselMini/ServicesCarousel";
import LanguagesCarousel from "../components/carouselMini/LanguagesCarousel";
import WhyUs from "../components/home/WhyUs";
import FrameworkCarousel from "../components/carouselMini/FrameworkCarousel";
import ProductOverview from "../components/home/ProductsOverview";
import ToolsCarousel from "../components/carouselMini/ToolsCarousel";

const Home = () => {
    return (
        <>
            {/* SEO Metadata */}
            <Helmet>
                <title>Home - Innovative Tech Solutions</title>
                <meta
                    name="description"
                    content="Discover innovative solutions for tomorrow’s challenges with cutting-edge technology and expert-driven services."
                />
                <meta name="keywords" content="technology, innovative solutions, consultation, frameworks, languages" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://atomicsysitsolutions.com/" />
                <meta property="og:title" content="Home - Innovative Tech Solutions" />
                <meta property="og:description" content="Atomic System InfoTech Solutions - Your Innovative & Trusted IT Solution Partner. Creating customized, cutting-edge software for startups and enterprises. 50+ skilled freelancers providing adaptable, scalable solutions with 24/7 support." />
                <meta property="og:url" content="https://atomicsysitsolutions.com/" />
                <meta property="og:type" content="website" />
            </Helmet>

            {/* Main Content */}
            <Banner
                title="Innovative Solutions for Tomorrow’s Challenges"
                subtitle="Empowering your business with cutting-edge technology and expert-driven solutions"
                buttonText="Book Free 15 Minutes Consultation Meeting"
            />
            <ServiceCarousel />
            <Introduction />
            <LanguagesCarousel />
            <WhyUs />
            <FrameworkCarousel />
            <ProductOverview />
            <ToolsCarousel />
        </>
    );
};

export default Home;
