import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { Code, Group, Public, SupportAgent } from '@mui/icons-material';

const overviewInfo = [
    {
        icon: <Code sx={{ fontSize: '3rem', color: '#057DD1' }} aria-label="Innovative Solutions Icon" />,
        title: 'Innovative Solutions',
        description: 'Creating customized, cutting-edge software to empower startups and enterprises alike in the digital world.',
    },
    {
        icon: <Group sx={{ fontSize: '3rem', color: '#057DD1' }} aria-label="Remote Expertise Icon" />,
        title: 'Remote Expertise',
        description: 'With a global team of 50+ skilled freelancers, we combine diverse talent and insights for agile solutions.',
    },
    {
        icon: <Public sx={{ fontSize: '3rem', color: '#057DD1' }} aria-label="Scalable and Reliable Icon" />,
        title: 'Scalable & Reliable',
        description: 'Offering adaptable, scalable services with the quality assurance and commitment that your business deserves.',
    },
    {
        icon: <SupportAgent sx={{ fontSize: '3rem', color: '#057DD1' }} aria-label="Dedicated Support Icon" />,
        title: 'Dedicated Support',
        description: 'Providing 24/7 dedicated support to ensure seamless operation and customer satisfaction at every step.',
    },
];

const AboutOverview = () => (
    <>
        <Box
            component="section"
            sx={{
                py: 4,
                px: 2,
                backgroundColor: '#E6E6E6',
                fontFamily: 'Playwrite New Zealand',
            }}
            aria-labelledby="about-heading"
        >
            <Typography
                id="about-heading"
                component="h2"
                variant="h4"
                sx={{
                    textAlign: 'center',
                    mb: 4,
                    fontWeight: 'bold',
                    color: '#030519',
                }}
            >
                About Atomic System InfoTech Solutions
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {overviewInfo.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Paper
                            component="article"
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.15)',
                                },
                            }}
                        >
                            {item.icon}
                            <Typography
                                component="h3"
                                variant="h6"
                                sx={{ fontWeight: 'bold', color: '#030519', mt: 2 }}
                            >
                                {item.title}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1, color: '#182759' }}>
                                {item.description}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    </>
);

export default AboutOverview;
